import PropTypes from 'prop-types';
import React from 'react';

function AutoCollectCard({ image, text }) {
  return (
    <div className="md:relative">
      <div className="lineRight" />
      <img
        src={`/img/auto-collect/${image}`}
        alt=""
        width="394"
        height="417"
        loading="lazy"
        className="mb-5 md:mb-6 w-full bg-cf-light-blue-bg"
      />
      <div className="text-2.5sm md:leading-[24px] text-center md:text-left">
        {text}
      </div>
    </div>
  );
}
AutoCollectCard.defaultProps = {
  image: '',
  text: '',
};
AutoCollectCard.propTypes = {
  image: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
export default AutoCollectCard;
