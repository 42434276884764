import React, { useRef } from 'react';
import Icon from '../components/Icon';
import Layout from '../components/Layout';
import Scrollspy from '../components/Scrollspy';
import SEO from '../components/SEO';
import Tabs from '../components/Tabs/Tabs';
import GetStarted from '../components/GetStarted';
import FAQ from '../components/FAQ';
import OtherProducts from '../components/OtherProducts/OtherProducts';
import GradientTagContainer from '../components/common/GradientTagContainer/GradientTagContainer';
import EnterprisePricing from '../components/common/EnterprisePricing/EnterprisePricing';
import CheckList from '../components/CheckList/CheckList';
import HeroLogoSlider from '../components/Sliders/HeroLogoSlider/HeroLogoSlider';
import TransparentVideo from '../components/TransparentVideo/TransparentVideo';
import {
  autoCollectWork,
  faqData,
  features,
  heroCheckList,
  heroLogoData,
  heroSliderSettings,
  otherProduct,
  powerAutoCollect,
  pricingData,
} from '../content/auto-collect';
import '../styles/auto-collect.scss';

function AutoCollect({ ...rest }) {
  const container = useRef();
  return (
    <Layout
      lightHeader
      {...rest}
      signInLink="https://merchant.cashfree.com/merchant/login?source-action=Autocollect%20Page&action=Sign%20In&button-id=SignIn_Navbar/"
      createAccountLink="https://merchant.cashfree.com/merchants/signup?source-action=Autocollect%20Page&action=Sign%20Up&button-id=StartNow_Navbar/">
      <SEO
        title="Virtual bank accounts for funds collection & payments reconciliation"
        description="Use virtual bank accounts & UPI IDs to collect payments & automatically reconcile all bank transfers to your account via IMPS, NEFT, RTGS, UPI & more."
        url="https://www.cashfree.com/virtual-bank-account/"
        keywords={[
          'Virtual Bank Account',
          'Auto Collect',
          'virtual account collection',
          'smart collection',
          'virtual payment',
        ]}
      />

      <section className="relative section-padding bg-cf-primary autocollect-hero overflow-hidden text-white">
        <div className="container relative">
          <div className="flex flex-wrap flex-col lg:flex-row lg:justify-between w-full lg:w-[120%] lg:mb-[20px] lg:mt-[40px]">
            <div className="relative z-[1] lg:w-[50%]">
              <h2 className="text-sm font-semibold pl-5 mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] mx-auto inline-block font-body">
                Auto Collect
              </h2>
              <div className="mb-5 text-xl md:text-2xl font-semibold max-w-[670px] font-heading">
                Collect funds with{' '}
                <h1 className="inline">virtual bank accounts</h1> &{' '}
                <h2 className="inline">automate payments reconciliation</h2>
              </div>
              <div className="text-opacity-80 mb-[30px] max-w-[620px] text-[16px] leading-[24px] md:text-2.5md">
                <h2 className="inline font-body">Collect funds</h2> seamlessly
                and use the power of unique{' '}
                <h2 className="inline font-body">Virtual Bank Accounts</h2> and
                Virtual UPI IDs to automatically reconcile all bank.
              </div>

              <ul className="p-0 mt-6 md:mt-7 mb-[30px] md:mb-8 list-none max-w-full flex flex-col md:flex-row md:items-center justify-start">
                {heroCheckList.map(({ id, listItem }) => (
                  <li
                    className="mb-[15px] mr-[24px] md:mr-[16px] last:mr-0 flex items-center"
                    key={id}>
                    <span className="w-5 h-5 flex flex-none justify-center items-center rounded-full mr-2 bg-white bg-opacity-25 self-center">
                      <Icon
                        name="tick"
                        width="9.21px"
                        height="6.29px"
                        fill="#fff"
                      />
                    </span>
                    <h3 className="font-body !m-0 text-2.5sm md:text-md">
                      {listItem}
                    </h3>
                  </li>
                ))}
              </ul>
              <div className="flex flex-wrap md:flex-nowrap justify-between md:justify-start mb-[48px] lg:mb-0">
                <a
                  className="button button-green w-full md:w-auto md:px-[27px] mb-[16px] md:mb-0 md:mr-[14px]"
                  href="https://merchant.cashfree.com/merchants/signup?source-action=Autocollect%20Page&action=Sign%20Up&button-id=StartNow_Hero/">
                  Create Account <span className="chevron" />
                </a>
                <a
                  className="button button-outline no-shadow w-full md:w-auto md:px-[30px]"
                  href="https://docs.cashfree.com/docs/auto-collect">
                  View Documentation <span className="chevron !mb-0" />
                </a>
              </div>
            </div>
            <div className="lg:w-1/2 lg:self-center">
              <div className="hidden md:block">
                <TransparentVideo
                  backgroundRef={container}
                  className="relative z-10"
                  poster="/img/auto-collect/poster.png"
                  width="1440"
                  height="1200"
                  mp4Src="/img/auto-collect/hero-video.mp4"
                  webmSrc="/img/auto-collect/hero-video.webm"
                />
              </div>
              <div className="block md:hidden">
                <TransparentVideo
                  backgroundRef={container}
                  className="relative z-10"
                  poster="/img/auto-collect/poster-mb.png"
                  width="1700"
                  height="1100"
                  mp4Src="/img/auto-collect/hero-video-mb.mp4"
                  webmSrc="/img/auto-collect/hero-video-mb.webm"
                />
              </div>
            </div>
          </div>
        </div>
        <section className="logo-container mt-14 md:mt-28">
          <div className="container">
            <div className="relative overflow-hidden z-10 max-w-[1000px] mx-auto">
              <HeroLogoSlider
                data={heroLogoData}
                imgUrl="/img/auto-collect"
                itemClassName="mx-[25px] lg:mx-auto flex items-center align-center h-[50px]"
                settings={heroSliderSettings}
              />
            </div>
          </div>
        </section>
      </section>
      <Scrollspy
        centeredLinks
        alwaysCenter
        leadContent={
          <div
            className="payout-nav-logo row row-no-margin justify-flex-start"
            style={{ minWidth: '190px' }}>
            <img
              src="/img/icons/auto-collect.svg"
              alt="auto-collect-icon"
              width="16"
              height="13"
              className="w-[20px] mr-[8px]"
            />
            <span>Auto Collect</span>
          </div>
        }>
        <section data-spy-title="Features" data-spy-id="deatures" className="">
          <div className="p-section-3 relative">
            <div className="container">
              <div className="text-shs md:text-shl font-semibold text-cf-hero max-w-[610px] mb-6 md:mb-8 font-heading autocollect-built">
                <span className="text-shs text-[23.6px] md:text-shl">
                  Built for multi-branch/
                </span>{' '}
                franchisee or <span>multi-department</span> businesses
              </div>
              <div className="flex md:flex-col flex-col-reverse">
                <a
                  className="button button-green w-full md:w-auto wide mt-[32px] md:mt-0  mb-0 md:mb-[60px] md:max-w-max"
                  href="https://merchant.cashfree.com/merchants/signup?source-action=Autocollect%20Page&action=Sign%20Up&button-id=StartNow_BuiltForMultiBranch/">
                  Create Account <span className="chevron" />
                </a>
                <div className="flex flex-wrap items-stretch md:mx-[-19px]">
                  {features.map((f) => (
                    <div
                      className="md:px-[19px] w-full md:w-1/3  flex py-2 md:py-0 "
                      key={f.id}>
                      <div className="py-[24px] md:py-[24px] px-[16px] bg-cf-light-grey flex justify-between flex-col rounded">
                        <div>
                          <div className="w-[40px] h-[40px]">
                            <img
                              src={f.icon}
                              alt=""
                              width="40"
                              height="40"
                              className="w-full"
                            />
                          </div>
                          <div className="text-3md font-semibold mt-2 md:mt-6 mb-3 text-cf-default">
                            {f.title}
                          </div>
                          <div className="text-2sm md:text-md">{f.text}</div>
                        </div>
                        <div className="bg-cf-primary px-4 pt-[33px] pb-3 text-2.5sm md:leading-[24px] text-white rounded relative mt-[20px] md:mt-[32px] border border-cf-stroke max-w-[500px] ml-[4px]">
                          <div className="ribbon-purple">Recommended for</div>
                          <span className="block mt-1 md:min-h-[72px]">
                            {f.recommended}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <GradientTagContainer
                type="hero"
                heading={<>Got any other unique use&nbsp;case?</>}
                subHeading={
                  <>
                    Do not worry, we have got you covered!{' '}
                    <span className="block md:inline">Talk to our team.</span>
                  </>
                }
                link="https://www.cashfree.com/contact-sales/?source-action=Autocollect%20Page&action=Contact%20Sales&button-id=ContactSales_BuiltForMultiBranch"
                button="sales"
              />
            </div>
          </div>
        </section>
        <section className="overflow-hidden">
          <div className="p-section-2 bg-cf-light-grey autocollect-work">
            <div className="container">
              <div className="text-shs md:text-shl font-semibold mb-3 md:mb-5 font-heading">
                How does <h2 className="inline">Cashfree Auto Collect</h2> work?
              </div>
              <div className="text-md mb-6">
                Create, collect and reconcile incoming{' '}
                <h2 className="inline font-body">
                  payments with Virtual Bank Accounts
                </h2>{' '}
                and UPI IDs.
              </div>
              <Tabs
                data={autoCollectWork}
                dropDown
                timerAutoPlay
                dropDownFontSize="text-[16px]"
              />
            </div>
          </div>
          <div className="p-section-3 overflow-hidden">
            <div className="container">
              <div className="flex flex-col items-center">
                <div className="font-heading text-lg md:text-xl font-semibold max-w-[830px] text-center">
                  Here is why leading businesses are using the power of{' '}
                  <h2 className="inline">virtual accounts</h2> with
                  Auto&nbsp;Collect
                </div>
                <div className="text-2.5sm md:text-md text-center mt-3 md:mt-5 mb-8 md:mb-16">
                  The most trusted and unique{' '}
                  <h2 className="inline font-body">e-collect solution</h2> in
                  the&nbsp;market.
                </div>
                <div className="flex flex-wrap items-stretch md:mx-[-19px]">
                  {powerAutoCollect.map(
                    ({ id, description, heading, icon }) => (
                      <div
                        key={id}
                        className="w-full md:w-1/3 md:px-[19px] mb-[16px] md:mb-[38px]">
                        <div className="bg-cf-light-grey px-[16px] py-[24px] h-full rounded lg:min-h-[220px]">
                          <div className="w-[30px] md:w-[40px] h-[30px] md:h-[40px] mb-[16px]">
                            <img
                              className="max-w-[30px] md:max-w-[40px] max-h-[30px] md:max-h-[40px] "
                              src={icon}
                              loading="lazy"
                              alt=""
                            />
                          </div>
                          <div>
                            <h3 className="font-body font-semibold mb-[8px]">
                              {heading}
                            </h3>
                            <p className="text-2sm">{description}</p>
                          </div>
                        </div>
                      </div>
                    ),
                  )}
                </div>
                <div className="flex flex-wrap md:flex-nowrap justify-between md:justify-center mb-0 mt-4 md:mt-[2px] w-full md:w-auto">
                  <a
                    className="button button-green wide-chevron w-full md:w-auto mb-[16px] md:mb-0 md:mr-[16px]"
                    href="https://merchant.cashfree.com/merchants/signup?source-action=Autocollect%20Page&action=Sign%20Up&button-id=StartNow_HereIsWhyLeadingBusinesses/">
                    Create Account <span className="chevron" />
                  </a>
                  <a
                    className="button button-light no-shadow text-cf-hero border-cf-stroke w-full md:w-auto px-[30px]"
                    href="https://docs.cashfree.com/docs/auto-collect">
                    View Developer Docs <span className="chevron dark !mb-0" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          data-spy-title="Pricing"
          data-spy-id="pricing"
          className="p-section-3 bg-cf-light-grey">
          <div className="container pricing-section-autocollect">
            <div className="flex flex-wrap items-center justify-between mx-[-15px]">
              <div className="w-full p-[15px] xl:w-[40%]">
                <p className="text-sm font-semibold text-cf-primary pl-5 mb-2 uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px]">
                  PRICING
                </p>
                <div className="text-cf-hero text-4md md:text-xl max-w-[373px] font-semibold font-heading">
                  Exclusive Introductory Pricing
                </div>
                <div className="mt-[20px] text-base max-w-[500px] mb-4">
                  Sign up for <h2 className="inline font-body">Auto Collect</h2>{' '}
                  today and manage your branch/department collections and
                  reconciliation with&nbsp;ease.
                </div>
                <CheckList
                  data={pricingData}
                  className="mb-0 md:mb-[32px]"
                  fontClass="text-2.5sm md:leading-[24px]"
                />
              </div>
              <div className="w-full p-[15px] xl:w-7/12">
                <div className="flex flex-col justify-center md:flex-row">
                  <div className="w-full xl:max-w-[334px] md:self-center">
                    <div className="p-4 md:p-6 starts-card">
                      <div className="md:mb-1 text-2.5sm md:leading-[24px]">
                        Starting at
                      </div>

                      <div className="inline text-cf-primary text-4md md:text-2xl starts-at relative font-medium">
                        0.3% <span className="text-4md">or</span> ₹8
                        <span className="text-2.5sm md:leading-[24px] text-cf-hero font-normal line-through decoration-1 pl-2">
                          1% or ₹50
                        </span>
                      </div>

                      <p className="mb-[24px] md:mt-[8px] text-2.5sm md:leading-[24px]">
                        whichever is lower
                      </p>
                      <p className="py-[24px] text-2.5sm md:leading-[24px] pricing-border">
                        Charged on each payment collected
                        on&nbsp;Auto&nbsp;Collect.
                      </p>
                      <a
                        className="button button-green w-full"
                        href="https://merchant.cashfree.com/merchants/signup?source-action=Autocollect%20Page&action=Sign%20Up&button-id=StartNow_Pricing/">
                        Create Account <span className="chevron" />
                      </a>
                    </div>
                  </div>
                  <EnterprisePricing link="https://www.cashfree.com/contact-sales/?source-action=Autocollect%20Page&action=Contact%20Sales&button-id=ContactSales_Pricing " />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Scrollspy>
      <section>
        <div className="container general-faqs">
          <FAQ
            open="0"
            data={faqData}
            supportLinkHref="https://www.cashfree.com/help/hc"
          />
        </div>
      </section>
      <OtherProducts data={otherProduct} />
      <GetStarted
        startNowLink="https://merchant.cashfree.com/merchants/signup?source-action=Autocollect%20Page&action=Sign%20Up&button-id=StartNow_Footer/"
        getInTouchLink="https://www.cashfree.com/contact-sales/?source-action=Autocollect%20Page&action=Contact%20Sales&button-id=ContactSales_Footer"
      />
    </Layout>
  );
}

export default AutoCollect;
