import React from 'react';
import AutoCollectCard from '../components/pages/AutoCollect/AutoCollectCard';

export const heroLogoData = [
  {
    id: 0,
    src: 'ccd.png',
    width: '142',
    height: '94',
    className: 'max-w-[71px]',
    alt: 'Cafe Coffee Day',
  },
  {
    id: 1,
    src: 'byjus.png',
    width: '216',
    height: '62',
    className: 'max-w-[108px]',
    alt: "Byju's",
  },
  {
    id: 2,
    src: 'ashok-leyland.png',
    width: '390',
    height: '120',
    className: 'max-w-[130px]',
    alt: 'Ashok Leyland',
  },
  {
    id: 3,
    src: 'khatabook.png',
    width: '258',
    height: '70',
    className: 'max-w-[129px]',
    alt: 'Khatabook',
  },
  {
    id: 4,
    src: '1pharmacy.svg',
    width: '92',
    height: '43',
    className: 'max-w-[92px]',
    alt: '1pharmacy',
  },
  {
    id: 5,
    src: 'mumbaimetro.png',
    width: '164',
    height: '86',
    className: 'max-w-[82px]',
    alt: 'mumbai metro',
  },
];
export const heroSliderSettings = {
  dots: false,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 2000,
  speed: 2000,
  variableWidth: false,
  slidesToShow: heroLogoData.length,
  infinite: false,
  rtl: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        rtl: true,
        autoplay: true,
        cssEase: 'linear',
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        swipeToSlide: true,
        centerMode: true,
        variableWidth: true,
      },
    },
  ],
};
export const features = [
  {
    id: 0,
    icon: '/img/auto-collect/home.svg',
    title: 'Running a multi-branch or franchisee-based business?',
    text:
      'Create unlimited virtual bank accounts and virtual UPI IDs and keep a track of individual branch sales in real-time.',
    recommended: (
      <>
        Restaurants, travel agencies, jewellery showrooms, theatres, pharmacies,
        quick eat outlets, and similar&nbsp;business.
      </>
    ),
  },
  {
    id: 1,
    icon: '/img/auto-collect/hat.svg',
    title:
      'Running a multi-department business as separate business cost centers?',
    text:
      'Create unique virtual bank accounts and UPI IDs for your individual departments and plan your working capital effectively.',
    recommended: (
      <>
        Hospitals, schools, universities, service centers - automobiles,
        and&nbsp;similar.
      </>
    ),
  },
  {
    id: 2,
    icon: '/img/auto-collect/bag.svg',
    title:
      'Managing wallets of multiple customers by offering financial services?',
    text:
      'Create unique virtual bank accounts and UPI IDs for your individual customers, share with them and get instant notifications when funds are added to the wallets.',
    recommended: (
      <>Stock brokers, asset management companies and investment&nbsp;firms.</>
    ),
  },
];

export const autoCollectWork = [
  {
    key: 0,
    heading: 'Virtual Bank Accounts',

    content: (
      <div className="md:mt-[60px] pt-[1px] md:pt-0 overflow-x-auto md:overflow-hidden">
        <div className="flex mx-[-12px] md:mx-[-19px] w-[225%] md:w-full">
          {[
            {
              id: 0,
              data: {
                image: 'p1-1.svg',
                text: (
                  <>
                    Create{' '}
                    <h2 className="inline font-body">
                      unique virtual accounts
                    </h2>{' '}
                    for each of your business units with Dashboards and REST
                    APIs.
                  </>
                ),
              },
            },
            {
              id: 1,
              data: {
                image: 'p1-2.svg',
                text: (
                  <>
                    Branch/Franchisee/Customer makes a payment via NEFT, RTGS,
                    IMPS, or via challan to the unique{' '}
                    <h2 className="inline font-body">virtual bank account</h2>{' '}
                    provided to them.
                  </>
                ),
              },
            },
            {
              id: 2,
              data: {
                image: 'p1-3.svg',
                text:
                  'Headquarter gets notified of successful payments via SMS and emails through webhooks, and the same gets reflected on the central dashboard.',
              },
            },
          ].map((p) => (
            <div
              className="w-full md:w-1/3 px-[12px] md:px-[19px] card mb-10 last:mb-[12px] md:mb-0"
              key={p.id}>
              <AutoCollectCard {...p.data} />
            </div>
          ))}
        </div>
      </div>
    ),
  },
  {
    key: 1,
    heading: 'Virtual UPI ID',
    content: (
      <div className="md:mt-[60px] pt-[1px] md:pt-0 overflow-x-auto md:overflow-hidden">
        <div className="flex mx-[-12px] md:mx-[-19px] w-[225%] md:w-full">
          {[
            {
              id: 0,
              data: {
                image: 'p2-1.svg',
                text:
                  'Create unique virtual UPI IDs for each of your business units with Dashboards and REST APIs.',
              },
            },
            {
              id: 1,
              data: {
                image: 'p2-2.svg',
                text:
                  'Branch/Franchisee/Customer makes a payment via any UPI app - Google Pay, PhonePe, Whatsapp, etc',
              },
            },
            {
              id: 2,
              data: {
                image: 'p2-3.svg',
                text:
                  'Headquarter gets notified of successful payments via SMS and emails through webhooks, and the same gets reflected on the central dashboard.',
              },
            },
          ].map((p) => (
            <div
              className="w-full md:w-1/3 px-[12px] md:px-[19px] card mb-10 last:mb-[12px] md:mb-0"
              key={p.id}>
              <AutoCollectCard {...p.data} />
            </div>
          ))}
        </div>
      </div>
    ),
  },
];

export const pricingData = [
  { id: 0, text: 'Zero Cost - Unlimited Virtual bank Accounts' },
  { id: 1, text: 'Zero Cost - Unlimited UPI IDs' },
];

export const powerAutoCollect = [
  {
    id: 0,
    icon: '/img/auto-collect/1.svg',
    heading: 'Real-Time Reconciliation',
    description:
      'With the power of webhooks and dashboards, match all incoming payments to individual branches/departments in real-time. Know who paid and when.',
  },
  {
    id: 1,
    icon: '/img/auto-collect/2.svg',
    heading: '365-Days Flexible Payment Settlements',
    description:
      'Enjoy 365-day payment settlements. From 30 minutes to 30 days, settle directly to your bank account 24x7 even on a  bank holiday.',
  },
  {
    id: 2,
    icon: '/img/auto-collect/3.svg',
    heading: 'Payments and fintech companies',
    description:
      'Collect payments via Auto Collect, and automatically split with multiple parties based on predefined percentages and settlement cycles.',
  },

  {
    id: 3,
    icon: '/img/auto-collect/4.svg',
    heading: 'Centralized Dashboard & Reporting',
    description:
      'Stay on top of your daily, weekly, and monthly sales of each business unit with easy-to-read graphs and downloadable reports.',
  },
  {
    id: 4,
    icon: '/img/auto-collect/5.svg',
    heading: 'Amount Lock and remitter Lock',
    description:
      'Receive fixed funds from KYC approved customers only by enabling amount and remitter lock to further increase the accuracy of your reconciliation process.',
  },
  {
    id: 5,
    icon: '/img/auto-collect/6.svg',
    heading: 'Activation Control & Payment Rejection',
    description:
      'Create/deactivate virtual accounts in real-time. Transfers initiated to a disabled virtual account get auto rejected.',
  },
];
export const faqData = [
  {
    id: 0,
    q: 'Is Autocollect the same as cash management services provided by banks?',
    a: (
      <p>
        Cashfree Autocollect helps not only collect incoming bank transfers and
        UPI payments but also track and automatically reconcile payments. Also,
        Cashfree Autocollect uses Virtual accounts and Virtual UPI IDs to
        collect payments, while most banks do not provide this facility.
      </p>
    ),
  },
  {
    id: 1,
    q: 'What is a Virtual Account?',
    a: (
      <>
        <p>
          A Virtual Account is a pass-through account linked to a real bank
          account acting as a layer, specifically used to improve inward payment
          reconciliations. When a business receives payments from multiple
          sources and over a period of time, it gets difficult to track who paid
          and for which reason. This is usually reconciled manually by matching
          reference numbers in the bank statements with screenshots provided by
          a payer. By creating multiple virtual accounts mapped to the same
          account, it becomes possible to reconcile different incoming
          transactions into a bank account.
        </p>
        <p>
          With Cashfree auto collect- cash management services, you can generate
          any number of virtual accounts or UPI IDs for each payer or invoice
          and share a/c details while receiving payments. For repeated customers
          you can use a dedicated virtual account or UPI ID.
        </p>
      </>
    ),
  },
  {
    id: 2,
    q: 'How does the challan flow works with virtual accounts?',
    a: (
      <>
        <p>
          Once a payer clicks on the NEFT/RTGS mode for making a payment, a
          challan is created which is nothing but a piece of document that has
          the bank account details of the benficiary
        </p>
        <p>
          With the help of our Auto Collect solution, a virtual account gets
          created every time a transaction is initiated.
        </p>
        <p>
          A unique prefix gets associated with the payment mode for example
          808071- that acts as a Unique identifier
        </p>
        <div>
          And a virtual account gets created with the combination of the unique
          prefix and Cashfree transaction id.
          <ul className="mt-4 list-disc">
            <li>
              For example, if the transaction id is 564162, there will be a
              virtual account associated with this transaction. 808071564162.
            </li>
          </ul>
        </div>
        <div>
          Any incoming payment to this account number will be associated with
          this transaction and we will mark the transaction as successful and
          the amount will get settled to the merchant on&nbsp;T+1.
          <ul className="mt-4 list-disc">
            <li>
              For instance, if TXN is successful on the 18th, the amount will be
              paid to the merchant on the&nbsp;19th.
            </li>
          </ul>
        </div>
      </>
    ),
  },
  {
    id: 3,
    q: 'Can I hold money in a Virtual account?',
    a: (
      <p>
        No, it is not possible to hold money in the account. The payment so
        received eventually passes to the linked actual bank account. The
        virtual account here is used only for payment reconciliation.
      </p>
    ),
  },
  {
    id: 4,
    q:
      'Some banks provide virtual bank account services, does cashfree have any advantage over such accounts? What are the shopify payment gateway India charges?',
    a: (
      <p>
        For most banks, Virtual account payments can be received only using NEFT
        or RTGS; IMPS payments fail. The payments are not instant and restricted
        by banking hours. Using Cashfree cash management services solution,
        funds can be remitted to the virtual account through all bank transfer
        modes including UPI, IMPS, NEFT, RTGS, and even Cheques. Cashfree also
        helps you create Virtual UPI IDs to accept payments through any UPI app
        such as WhatsApp or Google Pay. Moreover, for banks, reconciliation of
        payments happens through files sent over email, which is a once-a-day
        reconciliation process. For Cashfree, payment confirmation can be
        received through an API which is a real-time system update for receipt
        of funds. Cashfree also supports fully numeric virtual accounts, unlike
        many banks which work on all bank interfaces -- alpha-numeric bank
        accounts provided by banks do not work on many of the older bank
        interfaces, especially on mobile.
      </p>
    ),
  },
  {
    id: 5,
    q: (
      <>
        Can I use Auto Collect, the cash management services platform for
        outward payments as&nbsp;well?
      </>
    ),
    a: (
      <p>
        No, however for automating outward payments you can use Cashfree
        Payouts. It is a bulk disbursal solution that helps you send money to
        any UPI-BHIM ID, Paytm wallet, debit and credit card, or bank account or
        native wallet. With the inbuilt bank account verification feature, you
        can also verify the bank account details and ensure that payments hit
        the right beneficiary bank account. Checkout Payouts here.
      </p>
    ),
  },
  {
    id: 6,
    q: 'Is any KYC required for creating a virtual account?',
    a: (
      <p>
        No, there is no KYC required for creating the account. You can create
        any number of virtual accounts and virtual UPI IDs using Cashfree cash
        management services.
      </p>
    ),
  },
];
export const otherProduct = [
  {
    id: 0,
    title: 'UPI QR',
    body: (
      <>
        With unique printable QR code for each branch or franchisee, collect UPI
        payments via all UPI Apps and get transparency on all UPI payments
        collected
      </>
    ),
    url: 'https://www.cashfree.com/upi-qr-code/',
    iconname: '/img/icons/upi-qr.svg',
  },
  {
    id: 1,
    title: 'Easy Split',
    body: (
      <>
        Verify and onboard vendors, collect payments from customers, deduct
        commissions, and split payments between vendors or your own
        bank&nbsp;accounts.
      </>
    ),
    url: 'https://www.cashfree.com/easy-split/split-payment-gateway/',
    iconname: '/img/icons/easy-split.svg',
  },
  {
    id: 2,
    title: 'Payment Links',
    body:
      'No-code payment links to collect payments over WhatsApp, SMS, Facebook, Twitter and other channels.',
    url: 'https://www.cashfree.com/payment-links/',
    iconname: '/img/icons/payment-links.svg',
  },

  {
    id: 3,
    title: 'Subscriptions',
    body:
      'Accept recurring payments by auto-debiting customers’ accounts via standing instructions on card, e-mandate via net banking option or UPI AutoPay. ',
    url: 'https://www.cashfree.com/recurring-payment/',
    iconname: '/img/icons/subscription.svg',
  },
];
export const heroCheckList = [
  {
    id: 0,
    listItem: 'Zero cost creation',
  },
  {
    id: 1,
    listItem: '365 days settlements',
  },
  {
    id: 2,
    listItem: 'Real-time alerts',
  },
];
